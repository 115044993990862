import { Icon } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { useRouter } from 'next/router'
import { signInSchema } from '~/state/schemas/sign-in'
import { trpc } from '~/utils/trpc'
import { BiLogInCircle } from 'react-icons/bi'

export const SgidLoginButton = (): JSX.Element | null => {
  const router = useRouter()
  const sgidLoginMutation = trpc.auth.sgid.login.useMutation({
    onSuccess: ({ redirectUrl }) => {
      router.push(redirectUrl)
    },
  })

  const handleSgidLogin = () => {
    const options = signInSchema.parse(router.query)
    return sgidLoginMutation.mutate(options)
  }

  return (
    <Button
      isLoading={sgidLoginMutation.isLoading}
      onClick={handleSgidLogin}
      w={{ base: '20rem', lg: '30rem' }}
    >
      Login with Singpass <Icon as={BiLogInCircle} size="1.5rem" />
    </Button>
  )
}
